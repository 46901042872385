<template>
  <div id="box">
    <h1>查看患者治疗报告结果图表</h1>
    <p>（注：如果出现输入患者名字查询图表时数据不正常，请改为输入身份证）</p>
    <el-input v-model="key" placeholder="请输入患者姓名或身份证"> </el-input>
    <el-select v-model="etId" placeholder="请选择">
      <el-option value="睡眠觉醒MEQ">睡眠觉醒MEQ</el-option>
      <el-option value="抑郁量表HAMD">抑郁量表HAMD</el-option>
      <el-option value="焦虑量表BAI">焦虑量表BAI</el-option>
      <el-option value="睡眠质量量表PSQI">睡眠质量量表PSQI</el-option>
      <el-option value="抑郁症筛查量表PHQ">抑郁症筛查量表PHQ</el-option>
      <el-option value="产后抑郁量表EPDS">产后抑郁量表EPDS</el-option>
      <el-option value="老年抑郁量表GDS">老年抑郁量表GDS</el-option>
      <el-option value="嗜睡量表ESS">嗜睡量表ESS</el-option>
      <el-option value="生活质量量表QOL">生活质量量表QOL-AD</el-option>
      <el-option value="脑震荡后综合征量表RPQ">脑震荡后综合征量表RPQ</el-option>
      <el-option value="躁动行为量表CMAI">躁动行为量表CMAI</el-option>
    </el-select>
    <!-- 通过时间筛选 -->
    <el-date-picker
      v-model="value1"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择开始日期"
    >
    </el-date-picker>
    <el-date-picker
      v-model="value2"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择结束日期"
    >
    </el-date-picker>
    <el-button @click="initData" :disabled="show" type="primary" round
      >查询</el-button
    >
    <!-- 定义图表外层容器 -->
    <div id="myHomeChart" ref="homeEcharts"></div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../http/axios";
export default {
  data() {
    return {
      etId: "",
      E2Data: [],
      data: [],
      date: [],
      yArrdata: [],
      key: "",
      min: 0,
      max: 0,
      value1: "",
      value2: "",
      status: false,
    };
  },
  mounted() {
    this.dataChart();
  },
  updated() {
    // 发生改变是调用改属性
    this.time;
  },
  created() {},
  computed: {
    etIds() {
      if (this.etId == "睡眠觉醒MEQ") {
        this.min = 0;
        this.max = 100;
        return "E1";
      }
      if (this.etId == "抑郁量表HAMD") {
        this.min = 0;
        this.max = 100;
        return "E2";
      }
      if (this.etId == "抑郁症筛查量表PHQ") {
        this.min = 0;
        this.max = 30;
        return "E3";
      }
      if (this.etId == "产后抑郁量表EPDS") {
        this.min = 0;
        this.max = 30;
        return "E4";
      }
      if (this.etId == "老年抑郁量表GDS") {
        this.min = 0;
        this.max = 30;
        return "E5";
      }

      if (this.etId == "焦虑量表BAI") {
        this.min = 0;
        this.max = 65;
        return "E7";
      }
      if (this.etId == "嗜睡量表ESS") {
        this.min = 0;
        this.max = 30;
        return "E8";
      }
      if (this.etId == "生活质量量表QOL") {
        this.min = 0;
        this.max = 55;
        return "E9";
      }
      if (this.etId == "脑震荡后综合征量表RPQ") {
        this.min = 0;
        this.max = 65;
        return "E10";
      }
      if (this.etId == "躁动行为量表CMAI") {
        this.min = 0;
        this.max = 175;
        return "E11";
      }
      if (this.etId == "睡眠质量量表PSQI") {
        this.min = 0;
        this.max = 30;
        return "E12";
      }
    },
    // 控制key的空值时不显示数据
    show() {
      if (this.key == "") {
        this.key = null;
      }
    },
    // 如果没有时间传值的情况下
    time() {
      if (this.value1 == null) {
        this.value1 = "";
      }
      if (this.value2 == null) {
        this.value2 = "";
      }
    },
  },
  methods: {
    // 绘制图表
    dataChart() {
      //初始化图表，this.$refs.homeEcharts获取到图表容器
      this.myChart = this.$echarts.init(this.$refs.homeEcharts);
      // 初始化配置（官网实例详情左侧代码，直接复制过来按项目需求修改即可）
      this.myChart.setOption({
        title: {
          text: "评估情况",
          x: "center",
        },
        //具体点击某一项触发的样式内容
        tooltip: {
          trigger: "axis",
          type: "cross",

          // formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        //左上侧分类条形符
        legend: {
          orient: "vertical",
          left: "left",
          // data: [],
        },
        //折线图类型以及数据源
        xAxis: {
          type: "category",
          data: this.date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "评估情况",
            type: "line",
            //radius: '70%',
            //center: ['50%', '60%'],
            data: this.yArrdata,
          },
        ],
      });
    },

    // 添加实时数据
    async initData() {
      const data = await axios({
        url: `/ExamSolution/getlist?from=${this.value1}&to=${this.value2}&key=${this.key}`,
        method: "get",
      });
      if (this.key == null) {
        Message.warning("请输入内容");
      }
      if (data.data.msg == "请求处理正常") {
        this.status = false;
        this.E2Data = data.data.data;
        let E2Arr = this.E2Data.filter((item) => item.etId === this.etIds);
        this.yArrdata = E2Arr.map((obj) => {
          return obj.score;
        });
        this.date = E2Arr.map((obj) => {
          return obj.dateTime.slice(0, 10);
        });
        this.myChart.setOption({
          xAxis: {
            type: "category",
            data: this.date,
            data: this.date,
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            scale: true,
            name: "分数",
            min: this.min,
            max: this.max,
            interval: 5,
          },
          series: [
            {
              name: this.etId + "得分走势",
              type: "line",
              radius: "70%",
              data: this.yArrdata,
              smooth: true,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#1CD8D2", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#ffffff", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#5865FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "#5865FF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#5865FF", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              areaStyle: {}, //显示阴影
            },
          ],
        });
      }
    },
  },
};
</script>
<style scoped>
#box {
  width: 100vw;
  height: 100vh;
}
/* 给图表容器定义宽高 */
#myHomeChart {
  margin-top: 20px;
  width: 1400px;
  height: 700px;
  /* background: #000; */
}
.el-input {
  width: 200px;
}
p {
  font-size: 14px;
  color: red;
}
</style>
